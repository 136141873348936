import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Modal component consists of a container, a header, a body, and a footer. The header contains the title of the modal, the body contains the main content, and the footer contains any buttons or actions.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/modal-anatomy.svg",
      "alt": "anatomy modal guideline"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Header: Contains the title of the modal`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Body: Contains the main content of the modal`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Footer: Contains buttons or actions related to the modal`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Modal component can be used to display additional content, such as forms, notifications, or alerts. The user can interact with the modal by clicking on buttons or filling out forms.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        To display a form or input field
      </li>
      <li>
        To display a confirmation message
      </li>
      <li>To display important information or notifications</li>
      <li>To prompt the user to take a specific action</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        For navigation purposes
      </li>
      <li>
        To display large amounts of information that may not fit within the modal’s limited space
      </li>
      <li>
        To display information that is not essential to the user
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`The modal component should be accessible to all users, including those with disabilities. This can be achieved by using appropriate labels, contrast, and keyboard navigation. Accessibility Best Practices for the Modal Component:`}</p>
    <ol>
      <li parentName="ol">{`Keyboard Navigation: The modal component should be fully accessible using only a keyboard. This includes being able to open and close the modal, as well as navigate and interact with its content.`}</li>
      <li parentName="ol">{`Focus Management: The modal component should maintain focus within its content when opened, and return focus to the original element when closed. This ensures that users can navigate the page without losing their place.`}</li>
      <li parentName="ol">{`ARIA Roles: ARIA roles should be used to provide additional information about the modal component and its content to assistive technologies. For example, the modal component should have a role of “dialog” and the modal header should have a role of “heading”.`}</li>
      <li parentName="ol">{`Contrast: The text within the modal component should have a high contrast ratio with its background, ensuring that it is easily readable for users with visual impairments.`}</li>
      <li parentName="ol">{`Label Placement: All form fields and buttons within the modal component should have clear and concise labels. These labels should be placed in a visually prominent location, and their placement should not change as the user interacts with the modal.`}</li>
      <li parentName="ol">{`Close Button: The modal component should have a clearly visible close button, either in the header or footer of the modal, which can be activated using both the mouse and keyboard.`}</li>
      <li parentName="ol">{`Error Feedback: If there are any errors within the modal component, such as invalid form inputs, clear error feedback should be provided to the user. This feedback should be easy to understand and accessible to users with disabilities.`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Only use maximum 2 button option, and make it contrast, primary and secondary" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/modal-component-guideline-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use too many buttons or actions in the modal" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/modal-component-guideline-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`The content of the modal should be:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Relevant to the user`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Clear and concise`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Free of errors`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Easy to understand`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Visually appealing`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      